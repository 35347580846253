export const TypedList = [
    "NUS Computer Engineering", 
    "Software Engineering",
    "Hardware Design",
    "Machine Learning",
    "Data Science",
    "Retro GameBoy Modding",
    "Pet Shrimp",
    "DJing 2000's Music",
    "Making Gelato at Home"
  ];